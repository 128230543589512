import { Box } from "@mantine/core";
import Router from "next/router";
import React from "react";

import Button from "../components/Button/Button";
import Logo from "../components/Logo/Logo";
import Page from "../components/Page/Page";
import { PAGE_ROUTES } from "../constants/Navigation";

function Custom404() {
  return (
    <Page pageName="Privacy" fullScreen={true} allowUnauthenticated={true}>
      <Logo
        sx={{
          height: "60px",
          width: "100%",
          m: 2,
        }}
      />

      <Box my="xl" ta="center">
        <Box>Oh no! It looks like you&apos;re lost.</Box>
        <Button
          variant="outline"
          onClick={() => Router.push(PAGE_ROUTES.LANDING)}
          m="md"
        >
          RETURN TO HOME
        </Button>
      </Box>
    </Page>
  );
}

export default Custom404;
